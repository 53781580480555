/* ##### 2.0 Header Area CSS ##### */
.nav{transition: all .5s ease-in}
.navbar-brand {
    color: #fff;
    position: relative;
    z-index: 1;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    padding-top: 0;
    margin: 0;
}
.lh-55px{line-height: 55px}
.navbar-nav .nav-item .nav-link{
    padding: 20px 12px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
}
.navbar-nav .nav-item .nav-link:hover{color:#fed136;}


/* @-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
} */
.navbar-white .navbar-brand:hover{color:#fff}
.navbar-dark .navbar-brand:hover{color:#333}

/* mobile view */
@media (max-width:500px)
{
    .navbar{ background: rgb(215, 223, 239);}
    .navbar-nav{
        border-top:1px solid #D12424;
        color:#fff;
        z-index:1;
        margin-top:5px;
        overflow: hidden;
    }
    .navbar-nav .nav-item .nav-link{
        padding: 0.7em 1em!important;
        font-size: 100%;
        font-weight: 500;
    }

}
.navbar-white .navbar-toggler-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}
@media(max-width: 992px){
    .shrink{animation:none;}
    .login-btn{
        margin-left: 15px !important;
        margin-top: 15px !important
    }
    .navbar-collapse .navbar-nav .nav-item .nav-link{
        padding: 5px 12px;
        color: #333
    }
    .navbar-expand-lg.navbar-dark {background: rgb(215, 223, 239);}
    .navbar-collapse{
        padding-top: 20px;
        background: rgb(215, 223, 239)
    }
}
.navbar-white.shrink .navbar-brand,
.shrink .navbar-nav .nav-item .nav-link{
    color: #333
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-nav .nav-item .nav-link{color: #333}

.navbar-cyan .navbar-brand,
.navbar-cyan .navbar-nav .nav-item .nav-link{color: #04d0ff}

.white-popup {
    position: fixed !important;
    background: transparent !important;
}


/* ##### 2.0 Header Area CSS ##### */
.nav{transition: all .5s ease-in}
.navbar-brand {
    position: relative;
    z-index: 1;
    color: #000;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    /* margin-left: -250px; */
    padding-right: 50px;
}
.lh-55px{line-height: 55px}
.navbar-nav .nav-item .nav-link{
    padding: 20px 12px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #000;
}
.navbar-nav .nav-item .nav-link:hover{color:#D12424;}
.navbar-expand-md .navbar-nav .dropdown-menu{
    border-top:3px solid #D12424;
}
.dropdown-item:hover{}
nav{-webkit-transition: padding-top .3s,padding-bottom .3s;
    -moz-transition: padding-top .3s,padding-bottom .3s;
    transition: padding-top .3s,padding-bottom .3s;
    border: none;
    }
    
 .shrink {
     
    /* animation: .3s ease-in-out;
    animation-name: fadeInDown; */
    background-color: rgb(255, 255, 255);
    
}

/* @-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
} */
.navbar-white .navbar-brand:hover{color:#D12424}
.navbar-dark .navbar-brand:hover{color:#D12424}

/* mobile view */
@media (max-width:500px)
{
    .navbar{ background: rgb(255, 255, 255);}
    .navbar-nav{
        border-top:1px solid rgb(255, 255, 255);
        color:#fff;
        z-index:1;
        margin-top:5px;
    }
    .navbar-nav .nav-item .nav-link{
        padding: 0.7em 1em!important;
        font-size: 100%;
        font-weight: 500;
    }

}
.navbar-dark .navbar-toggler,
.navbar-white .navbar-toggler,
.navbar-cyan .navbar-toggler{
    background-color:#D12424;
    border-color: #333
}
.navbar-dark .navbar-toggler:hover,
.navbar-white .navbar-toggler:hover,
.navbar-cyan .navbar-toggler:hover{
    background-color:#D12424;
    border-color: #333;
    -webkit-box-shadow:0 3px 0 0 #25170e; 
    box-shadow: 0 2px 2px 3px #25170e9d;
}


.navbar-white .navbar-toggler-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}
@media(max-width: 992px){
    .shrink{animation:none;}
    .login-btn{
        margin-left: 15px !important;
        margin-top: 15px !important
    }
    .navbar-collapse .navbar-nav .nav-item .nav-link{
        padding: 5px 12px;
        color: #333
    }
    .navbar-expand-lg.navbar-dark {background: rgb(215, 223, 239);}
    .navbar-collapse{
        
        padding-top: 20px;
        background: #fff
    }
}
.navbar-white.shrink .navbar-brand,
.shrink .navbar-nav .nav-item .nav-link{
    color: #333
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-nav .nav-item .nav-link{color: #333}

.navbar-cyan .navbar-brand,
.navbar-cyan .navbar-nav .nav-item .nav-link{color: #04d0ff}


/* Header -- Social Icons */
.header-social-info a i {
    z-index: 1;
    padding-left: 15px;
    color: #D12424;
    font-size: 20px;
    padding-top: 20px;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}
.header-social-info a i:hover {
    color: #FF8C21 ;
}

@media only screen and (max-width: 800px) {
    div .stripe1 {
        visibility: hidden;
        opacity: 0;
    }
    div .stripe2 {
        visibility: hidden;
        opacity: 0;
    }
}

.stripe1 {
    position: absolute;
    margin-left: 25px;
    margin-bottom: 50px;
    z-index: 0;
    height: 36px;
    width: 8.5px;
    background-color: #D12424;
}
.stripe2 {
    position: absolute;
    margin-left: 10px;
    margin-bottom: 50px;
    z-index: 0;
    height: 36px;
    width: 8.5px;
    background-color: #D12424;
}

@media only screen and (max-width: 800px) {
    div .marketplace {
        margin-left: 16px;
        margin-top: -10px;
        text-align: center;
        padding-top: 30px;
        padding-left: 60px;
        font-size: 12px !important;
        font-family: 'Poppins';
        font-weight: 500;
        color:#D12424;
    }
}

.marketplace {
    position: relative;
    z-index: 1;
    margin-top: 3px;
    margin-left: 150px;
    height: 50px;
    width: 155px;
    padding-left: 64px;
    font-size: 12px !important;
    font-family: 'Poppins';
    font-weight: 500;
    color:#D12424;
    background-image: url(../../assets/img/core-img/market.png);
}
.marketplace:hover,
.marketplace:focus {
    border-color: #000;
    border-radius: 5px;
    -webkit-box-shadow:0 3px 0 0 #25170e; 
    box-shadow: 0 2px 3px 3px #25170e88;
    cursor: pointer;
}
